import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexSkydweller = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexSkydweller {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, model: { regex: "/Sky-Dweller/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex Skydweller Watches for Sale'}
      canonical={'/fine-watches/rolex/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Rolex Skydweller Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/search/#search=rolex+sky-dweller">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-skydweller-header.png"
                alt="Pre-Owned Certified Used Rolex Sky-Dweller Watches Header"
                aria-label="Used Rolex Sky-Dweller Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              ROLEX SKY-DWELLER: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX WATCHES
            </h1>
            <h2>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX WATCHES</h2>
            <p>
              <b>Pre-Owned Rolex Sky-Dweller</b>
              <br />
              The Rolex Sky-Dweller is the brand’s newest collection, having debuted in 2012. It is
              also one of the most complicated Rolex watch models in the company’s catalog boasting
              GMT, Date, Month, and Annual Calendar functionalities. Like other Rolex dress watches
              such as the Day-Date and Datejust, the Sky-Dweller comes equipped with a fluted bezel,
              however, at 42mm big, the Rolex Sky-Dweller watch is much larger. When Rolex first
              released the Sky-Dweller travel watch, it was exclusively available in gold and fitted
              with either Oyster bracelets or leather straps. Since then, the Rolex men’s watch
              Sky-Dweller collection has expanded with other case materials, bracelet styles, and
              dial colors. What’s more, certain references have been discontinued; therefore these
              can only be found as pre-owned used Rolex Sky-Dweller watches in the secondary market.
              <br />
              <br />
              <b>Buying and Selling Used Rolex Submariner Watches</b>
              <br />
              Today, you will find secondhand Sky-Dweller Rolex watches in steel, two-tone steel and
              gold, yellow gold, Everose gold, and white gold. The luxury watch secondary market
              offers a wider range of Sky-Dweller watches than the current Rolex catalog because it
              includes discontinued references, as well as current models. For example, pre-owned
              Rolex Sky-Dweller watches are available in white gold while new ones are not.
              Furthermore, there are used Rolex Sky-Dweller watches with leather straps, which are
              no longer in production. If you're ready now to chat with a Rolex Sky-Dweller expert,
              <a href="/chat-with-rich">CLICK HERE </a>
              and you will be directed to our great specialists.
              <br />
              <br />
              Popular Pre-Owned Used Rolex Sky-Dweller Watch References:
              <ul>
                <li>Sky-Dweller 326934, steel case and bracelet, white gold bezel</li>
                <li>Sky-Dweller 326933, steel and yellow gold case and bracelet</li>
                <li>Sky-Dweller 326938, yellow gold case and bracelet</li>
                <li>Sky-Dweller 326138, yellow gold case, leather strap</li>
                <li>Sky-Dweller 326238, yellow gold case, rubber Oysterflex bracelet</li>
                <li>Sky-Dweller 326939, white gold case and bracelet</li>
                <li>Sky-Dweller 326139, white gold case, leather strap</li>
                <li>Sky-Dweller 326935 Everose gold case and bracelet</li>
                <li>Sky-Dweller 326135 Everose gold case, leather strap</li>
                <li>Sky-Dweller 326235 Everose gold case, rubber Oysterflex bracelet</li>
              </ul>
              <br />
              <br />
              Gray &amp; Sons is an independent Rolex dealer; therefore, we can offer both inventory
              control and price flexibility. Our decision-makers are ready to make deals on our
              secondhand Rolex watch inventory, which includes used Sky-Dweller watches. Any
              preowned Rolex Sky-Dweller for sale on our website is in stock, and ready to ship
              immediately. Alternatively, if you’ve wondered, “where can I sell my used Rolex
              Sky-Dweller men’s watch?” get the best cash offer by visiting
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com </a>
              today.
              <br />
              <br />
              <b>
                Gray &amp; Sons is the Best Source for Buying and Selling Pre-Owned Rolex
                Sky-Dweller Watches
              </b>
              <br />
              <br />
              For over 40 years, Gray &amp; Sons has had the pleasure of serving thousands of happy
              customers at both our physical store (located next to the Bal Harbour Shops in
              Surfside/Miami and open six days a week) and through our leading e-commerce site.
              We’re proud that many of those customers have designated Gray &amp; Sons the #1 buyer
              and seller of pre-owned used Rolex Sky-Dweller watches.
              <br />
              <br />
              The Gray &amp; Sons store serves the Miami, Miami Beach, Coral Gables, Coconut Grove,
              South Beach, Bal Harbour, Surfside, Sunny Isles, Ventura, Fort Lauderdale, Pinecrest,
              and Brickle areas. Our on-site watch experts are ready to help you buy or sell p
              re-owned Rolex Sky-Dweller watches. Plus, we also have an independent Rolex
              Sky-Dweller watch repair facility onsite for all your maintenance needs.
              <br />
              <br />
              <a href="/">www.grayandsons.com </a>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/search/#search=rolex+sky-dweller">
                <button>SHOP ROLEX SKY-DWELLER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX SKY-DWELLER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexSkydweller
